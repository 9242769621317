import VideoPlayer from "./VideoPlayer";

const App =()=>{
  return (
    <>
   <VideoPlayer/>
    </>
  )
}


export default App;